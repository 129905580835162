<template>
    <div class="answer">
        <!-- 答题页面 -->
        <div class="user">
            <span class="span2">欢迎您~</span> <span class="span2">{{ userInfo.nickname }}</span> <span class="span2"
                @click="logout">退出登录</span>
        </div>
        <template v-if="!submitStatus">
            <div class="introduce">
                <p class="p2">指导语</p>
                <span class="span2">请您认真、如实地根据自己在一周内（过去的7天内，包括今天）的机体状况和实际感受程度，选择最合适的选项。可专注而快速地选择，请将90道题全部作答，不要漏掉任何一题。</span>
            </div>
            <div class="answerContent">
                <p class="p2 title">第{{ paging.p }} / <span>{{ gaugeList.length }}</span>题</p>
                <div class="questionContent" v-loading="loading"
                    v-for="item in gaugeList.slice((paging.p - 1) * paging.ps, paging.p * paging.ps)"
                    :key="item.problem.id">
                    <p class="p2"> <span v-if="item.problem.isAnswer === true" style="color:red">*</span>{{ paging.p }}.{{
                        item.problem.name }} <span v-if="item.problem
        .type === 1">（单选）</span><span v-if="item.problem
        .type === 2">（多选）</span>
                        <span v-if="item.problem
                            .type === 3">（图片单选）</span>
                        <span v-if="item.problem
                            .type === 4">（图片多选）</span>
                    </p>
                    <ul>
                        <li :class="i.selected ? 'selected' : ''" v-for="i in item.answerList"
                            @click="choiceAnswer(i, item)">
                            <span class="span2">{{ i.name }}</span>
                        </li>
                        <!-- <li></li>
                                                <li></li>
                                                <li></li> -->
                    </ul>
                    <div class="bottomBtn">
                        <p @click="lastQuestion"><span class="span2">上一题</span></p>
                        <p @click="nextQuestion(item)" v-if="paging.p < gaugeList.length"><span class="span2">下一题</span></p>
                        <p @click="submit(item)" v-else><span class="span2">提交</span></p>
                    </div>
                </div>

            </div>
        </template>
        <template v-else>
            <div class="submitSuccess">
                <div class="img">
                    <img src="../../../assets/images/service/gauge/submit.png" alt="">
                    <p>提交成功</p>
                </div>
                <div class="submitBtn">
                    <!-- <span @click="back">返回</span> -->
                    <span class="look span2" v-if="lookStatus" @click="toLook">查看报告</span>
                    <!-- <button @click="back">返回</button>
                                        <button>查看报告</button> -->
                </div>
            </div>
        </template>
    </div>
</template>

<script>





import { getGaugeTitle, submitProblemOne, submitAll, touristLogin } from '@/api/gauge'
export default {
    data() {
        return {
            type: 0, // 是否为第一次答题
            userInfo: {}, // 用户信息
            loading: false,
            gaugeList: [],
            paging: {
                p: 1,
                ps: 1
            },
            lookStatus: false,// 查看报告的状态
            submitStatus: false, // 是否提交答案
            time: 0, //做题时长
            timer: null, // 计时器
            ReportId: null, // 报告id
            isReport: null // 是否可以查看报告
        }
    },
    methods: {
        // 获取题目
        async gaugeData() {
            this.timeStart();
            let info = {
                planId: Number(this.$store.state.gaugeListInfo.planId),
                gaugeId: Number(this.$route.query.gaugeId),
                type: Number(this.$route.query.type),
                isRandom: Number(this.$route.query.isRandom),
                id: (JSON.parse(sessionStorage.getItem('userInfo'))).id
            };

            // console.log(info)
            const res = await getGaugeTitle(info);
            console.log(res);
            if (res.code === 200) {
                if (res.data.time) {
                    this.time = res.data.time;
                }
                // this.gaugeList = res.data;
                if (this.type === 1) {
                    this.gaugeList = res.data;
                } else {
                    this.gaugeList = res.data.problemVOList;
                    // this.nowId = res.data.id;
                     // 给每题添加一个状态用于判断是否把题做完了  继续答题时跳转到指定位置
             this.gaugeList.forEach(item => {
                    item.problem.finished = false;
                    item.answerList.forEach(ele => {
                        if (ele.selected === true) {
                            item.problem.finished = true;
                        }
                    })
                })
                // console.log(this.tableData);
                let arr = [];
                this.gaugeList.forEach(item => {
                    if (item.problem.finished === false) {
                        arr.push(item);
                    }
                })
                    if (arr.length > 0) {
                        this.paging.p = Number(arr[0].problem.orderNum);
                    }
                    else {
                        this.paging.p = arr[arr.length - 1].problem.orderNum;
                    }
                }
            }
            
                

        },
        // 选择答案
        async choiceAnswer(val, val2) {
            this.loading = true;
            // console.log(this.time)
            // console.log(val);
            // console.log(val2);
            // console.log(this.paging.p)
            let info = {
                answerDTOS: [
                    {
                        answerId: null,
                        score: null
                    }],
                gaugeId: null,
                planId: null,
                problemId: null,
                time: null,
                userId: (JSON.parse(sessionStorage.getItem('userInfo'))).id
                // isMerge: null
            }
            // val2.problem.finished = false;
            // 先判断是单选还是多选 如果是单选进入下一层判断
            if (val2.problem.type === 1 || val2.problem.type === 3) {
                // 如果是单选，把当前答案改为选中其他则为未选中。
                val.selected = true;
                val2.problem.finished = true;
                val2.answerList.forEach(item => {
                    if (item.id !== val.id) {
                        item.selected = false;
                    }
                });
                // 判断用户是否选中，当前选项是否为必答
                // console.log(val2.problem.finished)
                if (val2.problem.isAnswer === true && this.paging.p <= this.gaugeList.length) {

                    info.answerDTOS[0].answerId = val.id;
                    info.answerDTOS[0].score = val.score;
                    info.gaugeId = val2.problem.gaugeId;
                    info.planId = Number(this.$store.state.gaugeListInfo.planId);
                    // info.isMerge = this.planInfo.isMerge;
                    info.problemId = val2.problem.id;
                    info.time = this.time;
                    const res = await submitProblemOne(JSON.stringify(info));
                    console.log(res);
                    if (res.code === 200) {
                        this.loading = false;
                        console.log(this.paging.p)
                        if (this.paging.p < this.gaugeList.length) {
                            this.paging.p++;
                        }
                        this.ReportId = res.data.id;
                        this.isReport = res.data.isReport;

                    }

                }
            } else {
                val.selected = true;
                val2.problem.finished = true;
                // console.log(val2.answerList)
                let arr = [];
                val2.answerList.forEach(item => {
                    if (item.selected === true) {
                        arr.push(item)
                    }
                });
                console.log(arr)

                arr.forEach((item, index) => {
                    info.answerDTOS[index] = {
                        answerId: item.id,
                        score: item.score
                    }
                });
                info.gaugeId = val2.problem.gaugeId;
                info.planId = this.$store.state.gaugeListInfo.planId;
                // info.isMerge = this.planInfo.isMerge;
                info.problemId = this.$store.state.gaugeListInfo.planId;
                info.time = this.time;
                const res = await submitProblemOne(JSON.stringify(info));
                console.log(res);
                if (res.code === 200) {
                    this.loading = false;
                    console.log(this.paging.p)
                    // this.paging.p++;
                    this.ReportId = res.data.id;
                    this.isReport = res.data.isReport;
                }
            }
            console.log(this.loading)
        },

        // 上一题
        lastQuestion() {
            if (this.paging.p > 1) {
                this.paging.p--;
            } else {
                this.$message({
                    showClose: true,
                    message: '已是第一题！'
                });
            }
        },
        // 下一题
        nextQuestion(val) {
            console.log(val);
            // 先判断该题是否是必答题
            if (val.problem.isAnswer === true) {
                // 如果是必答题，并且已经做答，那么可以切换到下一题
                if (val.problem.finished === true && val.problem.isAnswer === true) {
                    if (this.paging.p < this.gaugeList.length) {
                        this.paging.p++;
                    } else {
                        this.$message({
                            showClose: true,
                            message: '已是最后一题！'
                        });
                    }
                } else { // 如果是必答题，但是没有作答，那么提示用户选择答案
                    this.$message({
                        showClose: true,
                        message: '请选择答案！'
                    });
                }

            } else { // 如果不是必答题，那么可以直接跳转到下一题
                this.paging.p++;
            }

        },
        timeStart() {
            this.time = 0;
            this.timer = setInterval(() => {
                this.time++;
            }, 1000);
        },
        timeStop() {
            clearInterval(this.timer);
        },
        // 点击提交
        async submit(val) {
            console.log(val)
            this.timeStop();

            // let info  = {gaugeId: this.gaugeInfo.id, planId: this.planInfo.id, isMerge: this.planInfo.isMerge, time: this.time,  userId: this.userId}
            let info = { ...this.$store.state.gaugeListInfo };
            console.log(info)
            info.gaugeId = Number(this.$route.query.gaugeId);
            info.time = this.time;
            info.userId = (JSON.parse(sessionStorage.getItem('userInfo'))).id;
            console.log(info)
            if (info.id) {
                delete info.id
            }
            // if(Number(localStorage.getItem('roleId')) === 2) {
            //    info =  {gaugeId: this.gaugeInfo.id, planId: this.planInfo.id, isMerge: this.planInfo.isMerge, time: this.time,  userId: this.userId, operatorId: JSON.parse(localStorage.getItem('userInfo')).id}
            // } else {
            //     info = {gaugeId: this.gaugeInfo.id, planId: this.planInfo.id, isMerge: this.planInfo.isMerge, time: this.time,  userId: this.userId}
            // }
            // 先判断是否所有的已经做完
            this.loading = true;
            // 判断是否把题目答完
            let arr = [];
            this.gaugeList.forEach(item => {
                if (item.problem.finished === false && item.problem.isAnswer === true) {
                    arr.push(item);
                }
            });
            // 如果该数组小于1说明所有的都已经答完
            if (arr.length < 1) {
                const res = await submitAll(JSON.stringify(info));
                console.log(res)
                if (res.code === 200) {
                    setTimeout(() => {
                        this.ReportId = res.data.id;
                        this.loading = false;
                        this.submitStatus = true;
                    }, 1000);
                    // 判断该计划是否开放报告
                    if (res.data.isReport === 1) {
                        this.lookStatus = true;
                    } else {
                        this.lookStatus = false;
                    }
                }

            } else {
                setTimeout(() => {
                    this.loading = false;
                    // this.submitStatus = true;
                }, 1000);
                this.$message({
                    showClose: true,
                    message: '您有必答题未选择！'
                });
            }
        },
        // 点击查看报告
        toLook() {

            this.$router.push({
                path: `/test/evaluation/report`,
                query: {
                    reportId: this.ReportId
                }
            })
        },
        // 提交后点击返回
        back() {
            // 判断是否为合并量表，不是的话返回量表页面
            // this.$router.push('/home/evaluation')
            // this.$router.push(`${localStorage.getItem('path')}`)
        },
        // 退出登录
        async logout() {
            //清除当前用户的登录信息,转换为游客的身份
            sessionStorage.clear();
            // location.reload();
            const res = await touristLogin();
            console.log(res);
            if (res.code === 200) {
                sessionStorage.setItem('token', res.data.token);
                sessionStorage.setItem('userInfo', JSON.stringify(res.data));
                sessionStorage.setItem('userType', 'tourist');
                this.$message({
                    showClose: true,
                    message: '已退出登录！'
                });
                setTimeout(() => {
                this.$router.push('/test/gauge');
                location.reload();
            }, 1000);
            }
            
            
        }
    },
    created() {
        this.type = Number(this.$route.query.type)
        this.gaugeData();
        // this.timeStart();
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        // console.log(this.$store.state.gaugeListInfo)
    },
    beforeDestroy() {
        this.timeStop();
    },
}
</script>

<style lang="scss" scoped>
.answer {
    .user {
        span {
            font-weight: 600;

            &:nth-child(1) {
                color: var(--titleFont-color);
            }

            &:nth-child(2) {

                display: inline-block;
                margin-left: 10px;
                margin-right: 20px;
                color: var(--memoFont-color);
            }

            &:nth-child(3) {
                cursor: pointer;
                padding: 4px 12px;
                display: inline-block;
                border: 1px solid var(--custom-color);
                color: var(--headFont-color);
            }
        }
    }

    .introduce {
        margin-top: 20px;

        .p2 {
            // height: 35px;
            width: 100%;
            padding: 7px 10px;
            background: var(--listStyle-color);
            margin-bottom: 10px;
            box-sizing: border-box;
            font-weight: 600;
            color: var(--titleFont-color);
        }

        .span2 {
            color: var(--memoFont-color);
            line-height: 22px;
        }
    }

    .answerContent {
        .title {
            width: 100%;
            padding: 7px 10px;
            box-sizing: border-box;
            font-weight: 600;
            color: var(--titleFont-color);
            background: var(--listStyle-color);
            margin-top: 40px;
            margin-bottom: 12px;

            span {
                color: var(--memoFont-color);
            }
        }

        .questionContent {
            .p2 {
                color: var(--titleFont-color);
            }

            ul {
                list-style: none;
                padding: 0px;
                margin: 0px;

                li {
                    cursor: pointer;
                    margin-top: 20px;
                    box-sizing: border-box;
                    padding: 8px 15px;
                    border-radius: 4px;

                    border: 1px solid var(--listStyle-color);
                }

                .selected {
                    background: var(--login-color);
                    color: white;
                }
            }
        }

        .bottomBtn {
            text-align: center;
            display: flex;
            margin-top: 40px;

            p {
                // display: inline-block;
                width: 50%;
                padding: 10px 0px;
                font-weight: 600;
                color: var(--headFont-color);
                cursor: pointer;

                &:first-child {
                    border: 1px solid #FACFCF;
                }

                &:last-child {
                    background: #FACFCF;
                }
            }
        }
    }

    .submitSuccess {
        .img {
            text-align: center;
            width: 100%;

            img {
                height: 140px;
            }

            margin-top: 50px;
            margin-bottom: 10px;
        }

        p {
            font-size: 18px;
            color: var(--titleFont-color);
            line-height: 25px;
        }

        .submitBtn {
            margin-top: 62px;
            cursor: pointer;

            // display: flex;
            span {
                text-align: center;
                display: inline-block;
                width: 100%;
                padding: 10px 0px;
                background: var(--custom-color);
                font-weight: 600;
                color: var(--headFont-color);
            }


        }
    }
}
</style>