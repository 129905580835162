var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer"},[_c('div',{staticClass:"user"},[_c('span',{staticClass:"span2"},[_vm._v("欢迎您~")]),_vm._v(" "),_c('span',{staticClass:"span2"},[_vm._v(_vm._s(_vm.userInfo.nickname))]),_vm._v(" "),_c('span',{staticClass:"span2",on:{"click":_vm.logout}},[_vm._v("退出登录")])]),(!_vm.submitStatus)?[_vm._m(0),_c('div',{staticClass:"answerContent"},[_c('p',{staticClass:"p2 title"},[_vm._v("第"+_vm._s(_vm.paging.p)+" / "),_c('span',[_vm._v(_vm._s(_vm.gaugeList.length))]),_vm._v("题")]),_vm._l((_vm.gaugeList.slice((_vm.paging.p - 1) * _vm.paging.ps, _vm.paging.p * _vm.paging.ps)),function(item){return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:item.problem.id,staticClass:"questionContent"},[_c('p',{staticClass:"p2"},[(item.problem.isAnswer === true)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.paging.p)+"."+_vm._s(item.problem.name)+" "),(item.problem
    .type === 1)?_c('span',[_vm._v("（单选）")]):_vm._e(),(item.problem
    .type === 2)?_c('span',[_vm._v("（多选）")]):_vm._e(),(item.problem
                        .type === 3)?_c('span',[_vm._v("（图片单选）")]):_vm._e(),(item.problem
                        .type === 4)?_c('span',[_vm._v("（图片多选）")]):_vm._e()]),_c('ul',_vm._l((item.answerList),function(i){return _c('li',{class:i.selected ? 'selected' : '',on:{"click":function($event){return _vm.choiceAnswer(i, item)}}},[_c('span',{staticClass:"span2"},[_vm._v(_vm._s(i.name))])])}),0),_c('div',{staticClass:"bottomBtn"},[_c('p',{on:{"click":_vm.lastQuestion}},[_c('span',{staticClass:"span2"},[_vm._v("上一题")])]),(_vm.paging.p < _vm.gaugeList.length)?_c('p',{on:{"click":function($event){return _vm.nextQuestion(item)}}},[_c('span',{staticClass:"span2"},[_vm._v("下一题")])]):_c('p',{on:{"click":function($event){return _vm.submit(item)}}},[_c('span',{staticClass:"span2"},[_vm._v("提交")])])])])})],2)]:[_c('div',{staticClass:"submitSuccess"},[_vm._m(1),_c('div',{staticClass:"submitBtn"},[(_vm.lookStatus)?_c('span',{staticClass:"look span2",on:{"click":_vm.toLook}},[_vm._v("查看报告")]):_vm._e()])])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduce"},[_c('p',{staticClass:"p2"},[_vm._v("指导语")]),_c('span',{staticClass:"span2"},[_vm._v("请您认真、如实地根据自己在一周内（过去的7天内，包括今天）的机体状况和实际感受程度，选择最合适的选项。可专注而快速地选择，请将90道题全部作答，不要漏掉任何一题。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../../assets/images/service/gauge/submit.png"),"alt":""}}),_c('p',[_vm._v("提交成功")])])
}]

export { render, staticRenderFns }